.cookie-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cookie-modal {
    background: white;
    margin: 0px 8px;
    padding: 10px;
    // min-width: 500px;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: relative;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    // height: 500px;

    .cookie-modal-title {
        margin: 4px 0px;
        font-size: 20px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 26px;
        cursor: pointer;
    }

    .cookie-policy-link {
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
        color: #597ef7;
        text-decoration: none;
    }

    .cookie-modal-content {

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        p {
            font-size: 14px;
            margin-bottom: 15px;
        }

        .description-1 {
            margin-left: 40px;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .label-1 {
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            input {
                width: 16px;
                height: 16px;
                accent-color: #597ef7;
            }
        }

        min-height: 300px;
        height: calc(100vh - 200px);
        max-height: 550px;
        overflow: auto;
    }


    .cookie-options {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;
        }
    }

    .cookie-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        gap: 20px;

        button {
            padding: 8px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;
        }

        .decline {
            background: white;
            border: 1.5px solid #597ef7;
            color: #597ef7;
        }

        .accept {
            background: #597ef7;
            color: white;
        }
    }

    .info-card-1 {
        display: flex;
        justify-content: center;

        .info-card-1-message {
            font-size: 16px;
            margin-top: 10px;
        }
    }

    .table-container-1 {
        width: 100%;
        overflow-x: auto;
        margin-top: 0px;

        table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;
        }

        thead {
            background: white;
            position: sticky;
            top: 0;
            z-index: 10;
        }

        th {
            text-align: left;
            font-weight: bold;
            padding: 0px 12px 0px 12px;
            border-bottom: 2px solid #0056b3;
            font-size: 14px;
            height: fit-content;
           
        }

        td {
            padding: 12px;
            border-bottom: 1px solid #ddd;
            font-size: 14px;
            vertical-align: top;
        
        }

        tr:last-child td {
            border-bottom: none;
        }
    }


}